<template v-slot:body>
  <b-row>
    <b-col lg="8" sm="12">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form  @submit.prevent="handleSubmit(register)">
          <ValidationProvider name="Attachment" vid="honorarium_attachment" rules="required">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="honorarium_attachment"
              slot-scope="{ valid, errors }"
            >
              <template v-slot:label>
                {{$t('globalTrans.attachment')}} <span class="text-danger">*</span>
              </template>
              <b-form-file
                id="honorarium_attachment"
                v-on:change="onLeftAttachmentChange"
                accept="image/*"
                v-model="circular.honorarium_attachment"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col text-right">
              <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
              &nbsp;
              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </b-col>
  </b-row>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '../../../../../config/api_config'
import { billPaymentFile } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getReportHeadingData()
      this.circular = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      test: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      circular: {
        id: '',
        circular_memo_no: 0
      },
      upload_attachment: [],
      upload_right_logo: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  methods: {
    onLeftAttachmentChange (e) {
      this.upload_attachment = e.target.files[0]
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    getReportHeadingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.circular).map(key => {
        if (key === 'honorarium_attachment') {
          formData.append(key, this.upload_attachment)
        } else {
          formData.append(key, this.circular[key])
        }
      })

      result = await RestApi.postData(trainingElearningServiceBaseUrl, billPaymentFile, formData, config)
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
