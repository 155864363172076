<template>
  <div class="inner-section">
         <card>
           <template v-slot:searchHeaderTitle>
              <h4 class="card-title">
                {{ $t('globalTrans.honour_amount') }} {{ $t('globalTrans.search') }}
              </h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.circular_memo_no')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                      <b-col lg="6" sm="12">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="batch_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.batch')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.batch_id"
                            :options="batchList"
                            id="batch_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button size="sm" variant="primary" @click="searchData">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('globalTrans.honour_amount') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="bill-payment-form" :class="'btn btn-add font-weight-bold'"> <i class="fas fa-plus"> </i> {{  $t('globalTrans.add_new') }}</router-link>
          </template>
          <template v-slot:body>
              <b-overlay :show="loadingState">
                <!-- <b-row>
                  <b-col sm="12">
                  <div class="quick-filter-wrapper">
                      <div class="quick-filter-left">
                      <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                          <b-dropdown-form>
                          <div
                              class="form-group"
                              v-for="(field, index) in labelData"
                              :key="index"
                              >
                              <b-form-checkbox
                                  :id="'checkbox-' + field.label"
                                  v-model="field.show"
                                  :name="'checkbox-' + field.label"
                                  value=1
                                  unchecked-value=0
                              >
                                  {{ ($i18n.locale==='bn') ? field.label : field.label }}
                              </b-form-checkbox>
                          </div>
                          </b-dropdown-form>
                      </b-dropdown>
                      </div>
                      <div class="quick-filter-right">
                      <b-form-group
                          :label="$t('menu.perpage')"
                          label-for="per-page-select"
                      >
                          <b-form-select
                          id="per-page-select"
                          v-model="search.limit"
                          :options="pageOptions"
                          size="sm"
                          ></b-form-select>
                      </b-form-group>
                      </div>
                  </div>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                      <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                          <template v-slot:cell(serial)="data">
                          {{ $n(data.item.serial + pagination.slOffset) }}
                          </template>
                          <template v-slot:cell(type_name)="data">
                            <span class="capitalize">{{ data.item.type_name }}</span>
                          </template>
                          <template v-slot:cell(personal_info_id)="data">
                            {{ ($i18n.locale==='bn') ? data.item.personal.name_bn : data.item.personal.name }}
                          </template>
                          <template v-slot:cell(attachment)="data">
                              <a target = '_blank' v-if="data.item.honorarium_attachment" :href="trainingElearningServiceBaseUrl + 'storage/uploads/attachment/' + data.item.honorarium_attachment" download>({{$t('globalTrans.view_download')}})</a>
                          </template>
                          <template v-slot:cell(action)="data">
                              <!-- <b-button :class="'btn btn-info text-light'"  v-b-modal.modal-4  size="sm" @click="edit(data.item.id)"><i class="ri-ball-pen-fill m-0"></i>  {{$t('globalTrans.attachment')}} </b-button> -->
                              <router-link :to="`bill-payment-details?circilarMemoNo=${data.item.circular_memo_no}&batchId=${data.item.batch_id}&paymentType=${data.item.payment_type}`" :class="'btn btn-success text-light'"><i class="fas fa-eye"></i></router-link>
                          </template>
                      </b-table>
                    </slot>
                      <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                  </b-col>
                </b-row>
              </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="$t('globalTrans.honour_amount')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Attachment :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
      </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentList, billGeneratePaymentUpdate, circularPublicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Attachment from './Attachment'
// import Payment from './Payment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Attachment
    //   Details
    },
    data () {
        return {
          circularLoading: false,
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          search: {
            org_id: [],
            batch_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_title_id: 0,
            training_status: 1,
            payment_type: 'Trainer',
            fiscal_year_id: [],
            limit: 20
          },
          editItemId: '',
          batchList: [],
          circularList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '13%' } },
          { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '13%' } },
            { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '13%' } },
            { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '13%' } },
          // { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_tim.bill_payment_entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
          // const labels = [
          //     { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          //     { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          //     { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
          //     { label: this.$t('elearning_config.organization'), class: 'text-center' },
          //     { label: this.$t('globalTrans.attachment'), class: 'text-center' },
          //     { label: this.$t('globalTrans.action'), class: 'text-center' }
          // ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'serial' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'training_type_bn' },
              { key: 'training_title_bn' },
              // { key: 'attachment' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'serial' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'training_type' },
              { key: 'training_title' },
              // { key: 'attachment' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.paymentStatusUpdate()
    },
    watch: {
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      }
    },
    methods: {
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      edit (item) {
        this.editItemId = item.id
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data, this.search.limit)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const divisionList = this.$store.state.CommonService.commonObj.divisionList
        const districtList = this.$store.state.CommonService.commonObj.districtList
        const upazilaList = this.$store.state.CommonService.commonObj.upazilaList
        const unionList = this.$store.state.CommonService.commonObj.unionList
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
          const districtObject = districtList.find(district => district.value === item.pre_district_id)
          const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
          const unionObject = unionList.find(union => union.value === item.pre_union_id)
          const divisionData = {
              division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
              division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
            district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
            upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
          }
          const unionData = {
            union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
            union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
          }
          const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
          const addressData = {
            address: address
          }
          return Object.assign({}, item, { serial: index }, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, addressData)
        })
        return listData
      },
      async paymentStatusUpdate () {
        if (this.$route.params.status && this.$route.query.trnsId) {
          const status = this.$route.params.status
          const trnsId = this.$route.query.trnsId
          await RestApi.getData(trainingElearningServiceBaseUrl, billGeneratePaymentUpdate + status, { trnsId: trnsId }).then(response => {
            if (response.success) {
              this.$toast.success({
                title: 'Success',
                message: response.message,
                color: '#D6E09B'
              })
            } else {
              this.$toast.error({
                title: 'Error',
                message: response.message
              })
            }
            this.loadData()
            this.$router.push('/training-e-learning-service/tim/bill-payment')
          })
        } else {
          this.loadData()
        }
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
